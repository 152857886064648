export const clientNameFooter = "MANATOSH - Yoga terapia";
export const titleMain = "";
export const subtitleMain = "";

export const aspectRatioLogoAbout = '2'; // width/height
export const aspectRatioLogoMain = '1'; // width/height

export const borderRadiusButtons = '10px';

export const nomeFornecedorCompleto = "Manatosh";
export const nomeFornecedor = "Manatosh";
export const artigo = "o";
export const artigoCaps = "O";

export const footerSocialData = [
  {
    type: "material-community",
    name: "instagram",
    link: "https://www.instagram.com/utheusyoga",
    profile: "@utheusyoga"
  },
  {
    type: "material-community",
    name: "youtube",
    link: "https://www.youtube.com/channel/UCof_DRno-RCAbQ6ZFFoIv8A",
  },
  // {
  //   type: "material-community",
  //   name: "whatsapp",
  //   link: "https://wa.me/message/KWKSZPRGPD2YI1",
  //   number: "(51) 98032-5196"
  // },
];

export const drawerClosedLinks = [
  // {
  //   title: "Fale Comigo",
  //   link: "https://instagram.com",
  // },
];

export const linkAppleStore = null
export const linkPlayStore = null

export const hasOutsideAboutPage = true;
export const drawerOpenedLinks = [{
  title: "Compre aqui",
  link: "http://site.utheusyoga.com.br/",
}];
